<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn color="primary" :to="{ name: 'QuestionPack.Create' }"
        >New Question Pack</v-btn
      >
    </div>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>Question Pack</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="options.search"
          prepend-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <data-table
      :headers="headers"
      :items="questionPacks"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.action`]="{ item: { id } }">
        <div style="width: 100px">
          <v-btn
            color="warning"
            :to="{ name: 'QuestionPack.Edit', params: { id } }"
            class="mt-1 mb-2"
            medium
            block
            >Edit</v-btn
          >
          <v-btn color="error" class="mb-1" @click="deletePrompt(id)" block
            >Delete</v-btn
          >
        </div>
      </template>
    </data-table>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Delete selected data? </v-card-title>

        <v-card-text>
          This action is irreversible. Deleting this data means other data that
          containing this data will also be deleted.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1"
            @click="deleteData()"
            :loading="loadingDelete"
            text
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import QuestionPack from "@/services/question-pack";

export default {
  name: "QuestionPack.Index",
  components: {
    DataTable
  },
  data() {
    return {
      totalData: 0,
      questionPacks: [],
      loading: true,
      loadingDelete: false,
      options: {
        search: undefined
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Subject", value: "subjectName" },
        { text: "Action", value: "action", sortable: false }
      ],
      dialog: false,
      selectedId: ""
    };
  },
  watch: {
    options: {
      handler() {
        this.getQuestionPacks();
      },
      deep: true
    }
  },
  created() {
    this.setNavbarTitle("Manage Question Pack");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getQuestionPacks() {
      this.loading = true;
      this.questionPacks = [];
      const questionPacks = await QuestionPack.index(this.options);
      this.questionPacks = questionPacks.data.data.question_packs;
      this.totalData = questionPacks.data.data.total_data;
      this.loading = false;
    },
    async deletePrompt(id) {
      this.selectedId = id;
      this.dialog = true;
    },
    async deleteData() {
      try {
        this.loadingDelete = true;
        await QuestionPack.delete(this.selectedId);
        this.$router.go(this.$router.currentRoute);
      } catch (error) {
        this.loadingDelete = false;
      }
    }
  }
};
</script>
